// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H4, H6, P } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 1;
		background: ${props.theme.white};
		color: ${props.theme.bc4};
	`
);

export const Content = styled(Div)(
	(props) => css`
		//-
	`
);

export const Heading = styled(H4)(
	(props) => css`
		margin: 0 0 1.2rem 0;

		${breakup.large` margin: 0 0 2.4rem 0; `}
	`
);

export const Text = styled(P)(
	(props) => css`
		margin: 0 0 2.4rem 0;
		opacity: 0.6;

		${breakup.large` margin: 0 0 3.6rem 0; `}
	`
);

export const Image = styled.div(
	(props) => css`
		position: relative;
		z-index: 4;
		height: 50vh;
		margin-left: 2.4rem;

		${breakup.medium`
			margin-left: calc(8.333vw);
			height: 75vh;
		`}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: -2.4rem;
			right: 0;
			bottom: 0;
			background: ${props.theme.bc1};

			${breakup.medium` left: calc(-8.333vw - 2.2rem); `}
			${breakup.large` display: none; `}
		}

		${breakup.large`
			height: auto;
			margin: 0;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: -${props.theme.padL};
			overflow: hidden;
		`}

		.gatsby-image-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;

			img {
				border-radius: 12rem 0 0 0;
				${breakup.large` border-radius: 24rem 0 0 0; `}
			}
		}
	`
);

export const Testimonial = styled.div(
	(props) => css`
		${props.theme.grad(180, props.theme.bc1, props.theme.bc2)}

		position: relative;
		z-index: 6;
		bottom: 0;
		left: 0;

		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: center;
		width: 100%;

		padding: ${props.theme.padS} 2.4rem;
		color: ${props.theme.white};

		${breakup.medium`
			padding: ${props.theme.padM} calc(8.333vw + 0rem);
		`}

		${breakup.large`
			${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
			width: calc(8.333vw * 8);
			height: 24rem;
			padding: 0 6rem;
			transform: translateX(-36%);
		`}

		svg {
			position: absolute;
			top: 6rem;
			right: 6rem;
			transform: rotate(180deg);
		}
	`
);

export const Quote = styled.blockquote(
	(props) => css`
		${pStyles}
		font-style: italic;
		width: 100%;

		${breakup.large` width: calc(8.333vw * 5); `}
	`
);

export const Author = styled(H6)(
	(props) => css`
		margin: 2.4rem 0 0 0;

		${breakup.large`
			position: absolute;
			bottom: 6rem;
			right: 6rem;
		`}

		&:before {
			content: 'by ';
			font-weight: ${props.theme.reg};
		}
	`
);
