// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H3, Div } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;
		background: ${props.theme.bc4};

		> div {
			transform-origin: bottom center;
			will-change: transform;
		}
	`
);

export const Image = styled.div(
	(props) => css`
		position: sticky;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		background: ${props.theme.black};
		overflow: hidden;

		height: calc(var(--vhs, 1vh) * 120);

		${breakup.large`
			${props.theme.vh};
		`}

		> div {
			position: relative;
			width: 100%;
			height: 100%;
			transform: scale(1.4) rotate(2deg);
			opacity: 1;
		}

		.gatsby-image-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;

			img {
				object-position: center right;

				${breakup.large`
					object-position: center center;
				`}
			}
		}
	`
);

export const Content = styled(Div)(
	(props) => css`
		position: relative;

		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;

		${props.theme.vhs};

		${breakup.large`
			${props.theme.vh};
		`}
	`
);

export const Text = styled(H3)(
	(props) => css`
		position: relative;
		opacity: 0;
	`
);
