// Imports
// ------
import React, { useRef, useEffect, useState } from 'react';
import Button from '@parts/Button';
import useLabelPicker from '@utils/useLabelPicker';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { loaderState } from '@states/loader';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import {
	Jacket,
	WhiteBG,
	PageTitle,
	Title,
	Intro,
	Image,
	Content,
	Heading,
	Text,
} from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function Hero() {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • State
	const [titleAni, setTitleAni] = useState(false);

	// NOTE • Data
	const { title, introTitle, introText, introImage, introButton } = useDato();

	// NOTE • Decide the label
	const buttonLabel = useLabelPicker(introButton.slug);

	// NOTE • Refs
	const target = useRef();
	const whitebg = useRef();
	const trigger = useRef();
	const titleRef = useRef();

	// NOTE • Onload - Animate Scroll
	useEffect(() => {
		const timer = setTimeout(() => {
			gsap.to(target.current, {
				scale: 1,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '0% 50%',
					end: '100% 50%',
					scrub: 0.5,
					markers: false,
				},
			});

			gsap.to(whitebg.current, {
				scaleY: 1,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '0% 50%',
					end: '100% 50%',
					scrub: 0.5,
					markers: false,
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// NOTE • Split Title Characters
	useEffect(() => {
		let Splitting;
		if (typeof document !== 'undefined') {
			Splitting = require('splitting');

			Splitting({
				target: titleRef.current,
				by: 'chars',
			});
		}
	}, []);

	// NOTE • Check for loader status
	useEffect(() => {
		if (loaderState.isNeeded === false) {
			setTitleAni(true);
		}
	}, [loaderState.isNeeded]);

	return (
		<Jacket padTop ref={trigger}>
			<WhiteBG ref={whitebg} />

			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} large={9} mpad>
					<PageTitle>
						<Title ref={titleRef} isAnimating={titleAni}>
							{title}
						</Title>
					</PageTitle>
				</Column>
			</Row>

			<Intro>
				<Row isExpanded isCollapsed isEqual>
					<Column small={12} large={6}>
						<Image>
							<div ref={target}>
								<GatsbyImage image={introImage.data} alt={introImage.alt} />
							</div>
						</Image>
					</Column>

					{bp.large && (
						<Column small={12} large={4} pushOnLarge={1} maxHeight>
							<Row isExpanded isEqual>
								<Column mpad maxHeight>
									<Content>
										<Heading>{introTitle}</Heading>
										<Text>{introText}</Text>
										<Button isLight label={buttonLabel} to={introButton.slug} />
									</Content>
								</Column>
							</Row>
						</Column>
					)}
				</Row>
			</Intro>
		</Jacket>
	);
}

export default observer(Hero);
