// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, Div, H1, H4, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 3;
		margin-top: 6rem;
		overflow: hidden;

		${breakup.large` margin: 0; `}
	`
);

export const WhiteBG = styled.div(
	(props) => css`
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: 0;
		right: 0;
		height: ${props.theme.padL};
		background: ${props.theme.white};
		overflow: hidden;
		transform: scaleY(0);
		transform-origin: bottom center;
	`
);

export const PageTitle = styled(Div)(
	(props) => css`
		position: relative;
		z-index: 2;
		padding: 0 0 ${props.theme.padS} 0;
		overflow: hidden;

		${breakup.medium`
			padding: 0 0 ${props.theme.padM} 0;
		`}

		${breakup.large`
			padding: 9.6rem 0 ${props.theme.padL} ;
		`}
	`
);

const slideIn = keyframes`
    from {
        transform: translateY(-1.2rem) rotate(-6deg);
        opacity: 0;
    }

    to {
        transform: translateY(0rem) rotate(0deg);
        opacity: 1;
    }
`;

export const Title = styled(H1)(
	(props) => css`
		.word {
			display: inline-flex;
		}

		.char {
			display: inline-flex;
			opacity: 0;

			${props.isAnimating &&
			css`
				animation: ${slideIn} 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
				animation-delay: calc(50ms * var(--char-index));
			`}
		}
	`
);

export const Image = styled.div(
	(props) => css`
		position: relative;
		z-index: 4;
		height: 75vh;
		overflow: hidden;
		border-radius: 0 12rem 0 0;

		${breakup.large`
			height: 100%;
			border-radius: 0 24rem 0 0;
		`}

		> div {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;
			transform: scale(1.2);
		}

		.gatsby-image-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
		}
	`
);

export const Intro = styled.div(
	(props) => css`
		${props.theme.grad(0, props.theme.bc4, `${props.theme.bc4}00`)}

		position: relative;
	`
);

export const Content = styled(Div)(
	(props) => css`
		${breakup.medium`
			padding: ${props.theme.padM} 0;
		`}

		${breakup.large`
			padding: ${props.theme.padL} 0 calc(${props.theme.padL} * 2);
		`}
	`
);

export const Heading = styled(H4)(
	(props) => css`
		margin: 0 0 1.2rem 0;

		${breakup.large`
			margin: 0 0 2.4rem 0;
		`}
	`
);

export const Text = styled(P)(
	(props) => css`
		margin: 0 0 2.4rem 0;

		${breakup.large`
			margin: 0 0 3.6rem 0;
		`}
	`
);
