import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query HomeWhatWeDo {
				home: datoCmsHomePage {
					animatedText: wwdAnimatedText
					heading: wwdHeading
					text: wwdText
					image: wwdImage {
						alt
						data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
					button: wwdButton {
						... on DatoCmsServicesPage {
							slug
						}
						... on DatoCmsContactPage {
							slug
						}
						... on DatoCmsAboutPage {
							slug
						}
						... on DatoCmsCareersPage {
							slug
						}
						... on DatoCmsNewsPage {
							slug
						}
					}
					testimonial {
						quote
						author
					}
				}
			}
		`
	);
	return data.home;
};
