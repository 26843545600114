// Imports
// ------
import React, { useRef, useEffect } from 'react';
import Button from '@parts/Button';
import Icon from '@icons';
import AnimatedText from '@parts/AnimatedText';
import useLabelPicker from '@utils/useLabelPicker';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useDato } from './data';

// Styles
// ------
import {
	Jacket,
	Content,
	Heading,
	Text,
	Image,
	Testimonial,
	Quote,
	Author,
} from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function WhatWeDo() {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Refs
	const target = useRef();
	const trigger = useRef();

	// NOTE • Data
	// ------
	const {
		animatedText,
		heading,
		text,
		button,
		image,
		testimonial: { quote, author },
	} = useDato();

	// NOTE • Decide the label
	const buttonLabel = useLabelPicker(button.slug);

	// NOTE • Onload - Animate Scroll
	useEffect(() => {
		const timer = setTimeout(() => {
			gsap.to(target.current, {
				x: `0%`,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '0% 80%',
					end: '100% 60%',
					scrub: 0.5,
					markers: false,
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Jacket ref={trigger}>
			<AnimatedText text={animatedText} />

			<Row isExpanded isCollapsed isEqual>
				<Column small={12} medium={6} pushOnMedium={1} large={4}>
					<Row isExpanded isEqual>
						<Column mpad>
							<Content padBottom>
								<Heading>{heading}</Heading>
								<Text>{text}</Text>
								<Button label={buttonLabel} to={button.slug} />
							</Content>
						</Column>
					</Row>
				</Column>

				<Column small={12} large={6} pushOnLarge={2}>
					<Image>
						<GatsbyImage image={image.data} alt={image.alt} />
					</Image>
				</Column>
			</Row>

			<Row isExpanded isCollapsed>
				<Column small={12} medium={11} large={8}>
					<Testimonial ref={target}>
						{bp.large && <Icon type='quote' />}
						<Quote>"{quote}"</Quote>
						<Author isBold>{author}</Author>
					</Testimonial>
				</Column>
			</Row>
		</Jacket>
	);
}

export default WhatWeDo;
