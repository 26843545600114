// Imports
// ------
import React from 'react';
import Button from '@parts/Button';
import useLabelPicker from '@utils/useLabelPicker';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useDato } from './data';

// Styles
// ------
import {
	Jacket,
	Image,
	Content,
	Heading,
	Text,
	OverlayImage,
	PurpleCircle,
} from './styles';

// Component
// ------
function Trust() {
	// NOTE • Breakpoionts
	const bp = useBreakpoint();

	// NOTE • Data
	const { heading, text, image, button, accreditation } = useDato();

	// NOTE • Label Decider
	const buttonLabel = useLabelPicker(button.slug);

	return (
		<Jacket pad>
			<PurpleCircle>
				<circle r={bp.large ? 420 : 150} />
			</PurpleCircle>

			<Row isExpanded isEqual>
				<Column
					small={12}
					medium={10}
					pushOnMedium={1}
					large={5}
					pushOnLarge={1}
					mpad>
					<Image>
						<GatsbyImage image={image.data} alt={image.alt} />

						{accreditation && (
							<OverlayImage>
								<GatsbyImage
									image={accreditation.image.data}
									alt={accreditation.image.alt}
								/>
							</OverlayImage>
						)}
					</Image>
				</Column>

				<Column
					small={12}
					medium={10}
					pushOnMedium={1}
					large={4}
					pushOnLarge={2}
					mpad>
					<Content>
						<Heading>{heading}</Heading>
						<Text>{text}</Text>
						<Button label={buttonLabel} to={button.slug} />
					</Content>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Trust;
