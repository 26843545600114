// Imports
// ------
import React from 'react';
import Hero from './Hero';
import WhatWeDo from './WhatWeDo';
import Trust from './Trust';
import Mission from './Mission';
import LatestNews from '@parts/LatestNews';
import CallToAction from '@parts/CallToAction';

// Component
// ------
function Home() {
	return (
		<>
			<Hero />
			<WhatWeDo />
			<Trust />
			<Mission />
			<LatestNews />
			<CallToAction />
		</>
	);
}

export default Home;
