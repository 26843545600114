import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query HomeTrust {
				dato: datoCmsHomePage {
					heading: trustHeading
					text: trustText
					image: trustImage {
						alt
						data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
					accreditation {
						image {
							alt
							data: gatsbyImageData(
								width: 188
								height: 90
								placeholder: BLURRED
								layout: FIXED
							)
						}
					}
					button: trustButton {
						... on DatoCmsServicesPage {
							slug
						}
						... on DatoCmsContactPage {
							slug
						}
						... on DatoCmsAboutPage {
							slug
						}
						... on DatoCmsCareersPage {
							slug
						}
						... on DatoCmsNewsPage {
							slug
						}
					}
				}
			}
		`
	);
	return data.dato;
};
