// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import PageTransition from '@parts/PageTransition';
import Home from '@parts/Home';
import { graphql } from 'gatsby';

// Component
// ------
function IndexPage({ transitionStatus, entry, exit, data }) {
	// NOTE • Props
	const transProps = {
		status: transitionStatus,
		entry: entry,
		exit: exit,
	};

	return (
		<>
			<Seo content={data.page.seo} schema={data.page.schema} />

			<PageTransition {...transProps}>
				<Home />
			</PageTransition>
		</>
	);
}

export default IndexPage;

// GraphQL
// ------
export const query = graphql`
	query Home {
		page: datoCmsHomePage {
			schema
			seo: seoMeta {
				title
				desc: description
				card: twitterCard
				image {
					url
				}
			}
		}
	}
`;
