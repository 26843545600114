// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, Div, H4, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;
		background: ${props.theme.white};
		color: ${props.theme.bc4};
		overflow: hidden;
	`
);

export const Content = styled(Div)(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: center;
		height: 100%;
		margin: 2.4rem 0 0 0;

		${breakup.large` margin: 0; `}
	`
);

export const Heading = styled(H4)(
	(props) => css`
		margin: 0 0 1.2rem 0;

		${breakup.large` margin: 0 0 2.4rem 0; `}
	`
);

export const Text = styled(P)(
	(props) => css`
		margin: 0 0 2.4rem 0;
		opacity: 0.6;

		${breakup.large` margin: 0 0 3.6rem 0; `}
	`
);

export const Image = styled.div(
	(props) => css`
		position: relative;
		z-index: 4;
		height: 50vh;

		${breakup.medium`
			height: 75vh;
		`}

		${breakup.large`
			height: 75vh;
			min-height: 60rem;
			max-height: 72rem;
		`}

		> .gatsby-image-wrapper {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;

			img {
				border-radius: 0 12rem 0 0;

				${breakup.large`
					border-radius: 0rem 24rem 0 0;
				`}
			}
		}
	`
);

export const OverlayImage = styled.figure(
	(props) => css`
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);

		${breakup.large`
			transform: translateX(-50%) translateY(0%);
		`}

		> .gatsby-image-wrapper {
			max-width: 10.5rem;
			max-height: 5rem;

			${breakup.large`
				max-width: 18.8rem;
				max-height: 9rem;
			`}
		}
	`
);

const circleAni = (props) => keyframes`
	to {
		transform: rotate(360deg);
	}
`;

export const PurpleCircle = styled.svg(
	(props) => css`
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 30rem;
		height: 30rem;
		overflow: visible;

		${breakup.large`
			width: 84rem;
			height: 84rem;
		`}

		circle {
			stroke: ${props.theme.bc2};
			stroke-width: 3.6rem;
			fill: transparent;

			stroke-linecap: round;
			transform: rotate(0deg);

			${breakup.medium`
				stroke-width: 8.4rem;
				stroke-dasharray: 120rem;
				stroke-dashoffset: 120rem;

				animation: ${circleAni} 4s linear infinite both;
			`}
		}
	`
);
