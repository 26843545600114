import { useStaticQuery, graphql } from 'gatsby';

export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query HomeHero {
				home: datoCmsHomePage {
					title: heroTitle
					introTitle: heroIntroHeading
					introText: heroIntroText
					introImage: heroFeaturedImage {
						alt
						data: gatsbyImageData(
							width: 720
							height: 705
							placeholder: BLURRED
							layout: FULL_WIDTH
						)
					}
					introButton: heroIntroButton {
						... on DatoCmsServicesPage {
							slug
						}
						... on DatoCmsContactPage {
							slug
						}
						... on DatoCmsAboutPage {
							slug
						}
						... on DatoCmsCareersPage {
							slug
						}
						... on DatoCmsNewsPage {
							slug
						}
					}
				}
			}
		`
	);
	return data.home;
};
