// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Icon({ type, className, onClick }) {
	const shared = {
		height: '24px',
		width: '24px',
		viewBox: '0 0 24 24',
		xmlns: 'http://www.w3.org/2000/svg',
		className: className,
		onClick: onClick,
	};

	if (type === 'whatsapp')
		return (
			<Jacket {...shared}>
				<path d='M0.05369,24l1.68771-6.16271c-1.04102-1.80344-1.58881-3.84947-1.58796-5.94549 C0.15607,5.33464,5.49277,0,12.04988,0c3.18237,0.00127,6.16932,1.23986,8.41533,3.48773 c2.24607,2.24788,3.48232,5.23587,3.48109,8.41356c-0.00266,6.55716-5.33983,11.8925-11.89626,11.8925h-0.00003h-0.00487 c-1.99106-0.00082-3.9474-0.50012-5.68498-1.44752L0.05369,24z M6.65233,20.19318l0.36107,0.21425 c1.5181,0.90064,3.25833,1.3771,5.03255,1.37779h0.00405c5.44987,0,9.88551-4.43425,9.88772-9.88468 c0.00101-2.64119-1.0265-5.1247-2.89334-6.99306c-1.86684-1.86836-4.34946-2.89789-6.99046-2.8989 c-5.45405,0-9.88973,4.43393-9.89191,9.88399c-0.00076,1.86766,0.52201,3.68667,1.51178,5.26048l0.23513,0.37395l-0.99891,3.64756 L6.65233,20.19318z' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.07668,6.92043 C8.83542,6.34098,8.59035,6.41938,8.40789,6.4103c-0.17318-0.00862-0.37154-0.01044-0.5697-0.01044S7.31801,6.47422,7.04553,6.77169 C6.77307,7.06918,6.0052,7.7881,6.0052,9.25069c0,1.46259,1.06511,2.8756,1.21374,3.07393 c0.14861,0.19833,2.09602,3.1998,5.07784,4.48696c0.70918,0.30615,1.26289,0.48895,1.69455,0.62597 c0.71212,0.22611,1.36011,0.19422,1.87228,0.11772c0.57108-0.08529,1.75864-0.71889,2.00636-1.41301 c0.24769-0.69412,0.24769-1.28908,0.17337-1.41304c-0.07428-0.12392-0.27246-0.1983-0.56969-0.34706 c-0.29726-0.14872-1.75867-0.86765-2.03116-0.96679c-0.27246-0.09915-0.4706-0.14873-0.66877,0.14875 c-0.19814,0.29745-0.7678,0.9668-0.9412,1.1651c-0.17337,0.19833-0.34677,0.22314-0.644,0.07438 c-0.29726-0.14872-1.25504-0.46253-2.3904-1.47489c-0.88362-0.78789-1.48023-1.76108-1.65362-2.05856 c-0.17339-0.29748-0.01844-0.45833,0.13036-0.60645c0.1337-0.13313,0.29723-0.34706,0.44586-0.52059 c0.14861-0.17353,0.19816-0.29748,0.29723-0.49578c0.09909-0.19833,0.04954-0.37186-0.02477-0.5206 C9.91886,8.978,9.3244,7.51538,9.07668,6.92043z'
				/>
			</Jacket>
		);

	if (type === 'facebook')
		return (
			<Jacket {...shared}>
				<path d='M24,12.072A12,12,0,1,0,10.125,23.926V15.541H7.078V12.072h3.047V9.428c0-3.007,1.792-4.669,4.532-4.669a18.611,18.611,0,0,1,2.687.234V7.947H15.83a1.734,1.734,0,0,0-1.947,1.49,1.71,1.71,0,0,0-.008.385v2.25H17.2l-.532,3.469h-2.8v8.385A12,12,0,0,0,24,12.072Z' />
			</Jacket>
		);

	if (type === 'instagram')
		return (
			<Jacket {...shared}>
				<path d='M12,2.982c2.937,0,3.285.011,4.445.064a6.072,6.072,0,0,1,2.042.379,3.4,3.4,0,0,1,1.265.823,3.4,3.4,0,0,1,.823,1.265,6.072,6.072,0,0,1,.379,2.042c.053,1.16.064,1.508.064,4.445s-.011,3.285-.064,4.445a6.072,6.072,0,0,1-.379,2.042,3.644,3.644,0,0,1-2.088,2.088,6.072,6.072,0,0,1-2.042.379c-1.16.053-1.508.064-4.445.064s-3.285-.011-4.445-.064a6.072,6.072,0,0,1-2.042-.379,3.4,3.4,0,0,1-1.265-.823,3.4,3.4,0,0,1-.823-1.265,6.072,6.072,0,0,1-.379-2.042c-.053-1.16-.064-1.508-.064-4.445s.011-3.285.064-4.445a6.072,6.072,0,0,1,.379-2.042,3.4,3.4,0,0,1,.823-1.265,3.4,3.4,0,0,1,1.265-.823,6.072,6.072,0,0,1,2.042-.379c1.16-.053,1.508-.064,4.445-.064M12,1c-2.987,0-3.362.013-4.535.066a8.108,8.108,0,0,0-2.67.511A5.625,5.625,0,0,0,1.577,4.8a8.108,8.108,0,0,0-.511,2.67C1.013,8.638,1,9.013,1,12s.013,3.362.066,4.535a8.108,8.108,0,0,0,.511,2.67A5.625,5.625,0,0,0,4.8,22.423a8.108,8.108,0,0,0,2.67.511C8.638,22.987,9.013,23,12,23s3.362-.013,4.535-.066a8.108,8.108,0,0,0,2.67-.511A5.625,5.625,0,0,0,22.423,19.2a8.108,8.108,0,0,0,.511-2.67C22.987,15.362,23,14.987,23,12s-.013-3.362-.066-4.535a8.108,8.108,0,0,0-.511-2.67A5.625,5.625,0,0,0,19.2,1.577a8.108,8.108,0,0,0-2.67-.511C15.362,1.013,14.987,1,12,1Z' />
				<path d='M12,6.351A5.649,5.649,0,1,0,17.649,12,5.649,5.649,0,0,0,12,6.351Zm0,9.316A3.667,3.667,0,1,1,15.667,12,3.667,3.667,0,0,1,12,15.667Z' />
				<circle cx='17.872' cy='6.128' r='1.32' />
			</Jacket>
		);

	if (type === 'twitter')
		return (
			<Jacket {...shared}>
				<path d='M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z' />
			</Jacket>
		);

	if (type === 'codepen')
		return (
			<Jacket {...shared}>
				<path d='M24,8.2C24,8.2,24,8.2,24,8.2c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-11-7.3 c-0.3-0.2-0.8-0.2-1.1,0l-11,7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1v7.3 c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l11,7.3c0.2,0.1,0.4,0.2,0.6,0.2 c0.2,0,0.4-0.1,0.6-0.2l11-7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1V8.3 C24,8.3,24,8.3,24,8.2z M13,3l8.1,5.4l-3.6,2.4l-4.5-3V3z M11,3v4.8l-4.5,3L2.9,8.3L11,3z M2.1,10.3L4.6,12l-2.6,1.7V10.3z M11,21 l-8.1-5.4l3.6-2.4l4.5,3V21z M12,14.4L8.4,12L12,9.6l3.6,2.4L12,14.4z M13,21v-4.8l4.5-3l3.6,2.4L13,21z M21.9,13.7L19.4,12l2.6-1.7 V13.7z' />
			</Jacket>
		);

	if (type === 'dribbble')
		return (
			<Jacket {...shared}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M22.1,13.6c-0.4-0.1-3.2-1-6.4-0.4c1.3,3.7,1.9,6.7,2,7.3C20,19,21.7,16.5,22.1,13.6z M16,21.5c-0.2-0.9-0.7-4-2.2-7.8 c0,0,0,0-0.1,0c-5.8,2-7.9,6-8,6.4c1.7,1.4,3.9,2.2,6.3,2.2C13.4,22.3,14.8,22,16,21.5z M4.4,18.9c0.2-0.4,3-5.1,8.3-6.8 c0.1,0,0.3-0.1,0.4-0.1c-0.3-0.6-0.5-1.2-0.8-1.7c-5.1,1.5-10.1,1.5-10.5,1.5c0,0.1,0,0.2,0,0.3C1.8,14.6,2.7,17.1,4.4,18.9z M2,9.9 c0.5,0,4.7,0,9.5-1.2c-1.7-3-3.5-5.6-3.8-5.9C4.8,4.1,2.6,6.7,2,9.9z M9.6,2.1c0.3,0.4,2.1,2.9,3.8,6c3.6-1.4,5.2-3.4,5.4-3.7 C17,2.7,14.6,1.8,12,1.8C11.2,1.8,10.4,1.9,9.6,2.1z M19.9,5.5c-0.2,0.3-1.9,2.5-5.7,4c0.2,0.5,0.5,1,0.7,1.5 c0.1,0.2,0.1,0.4,0.2,0.5c3.4-0.4,6.8,0.3,7.1,0.3C22.2,9.5,21.4,7.3,19.9,5.5z'
				/>
			</Jacket>
		);

	if (type === 'linkedin')
		return (
			<Jacket {...shared}>
				<path d='M23,0H1C0.4,0,0,0.4,0,1v22c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z M7.1,20.5H3.6V9h3.6 V20.5z M5.3,7.4c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1C7.4,6.5,6.5,7.4,5.3,7.4z M20.5,20.5h-3.6 v-5.6c0-1.3,0-3-1.8-3c-1.9,0-2.1,1.4-2.1,2.9v5.7H9.4V9h3.4v1.6h0c0.5-0.9,1.6-1.8,3.4-1.8c3.6,0,4.3,2.4,4.3,5.5V20.5z' />
			</Jacket>
		);

	if (type === 'phone')
		return (
			<Jacket {...shared}>
				<path
					d='M21 11H13L9 13V3C9 2.46957 9.21071 1.96086 9.58579 1.58579C9.96086 1.21071 10.4696 1 11 1H21C21.5304 1 22.0391 1.21071 22.4142 1.58579C22.7893 1.96086 23 2.46957 23 3V9C23 9.53043 22.7893 10.0391 22.4142 10.4142C22.0391 10.7893 21.5304 11 21 11Z'
					strokeWidth='2'
					strokeMiterlimit='10'
					strokeLinecap='square'
					fill='none'
					className='bubble'
				/>
				<path
					d='M6 3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5V21C2 21.5304 2.21071 22.0391 2.58579 22.4142C2.96086 22.7893 3.46957 23 4 23H15C15.5304 23 16.0391 22.7893 16.4142 22.4142C16.7893 22.0391 17 21.5304 17 21V14'
					strokeWidth='2'
					strokeMiterlimit='10'
					strokeLinecap='square'
					fill='none'
					className='phone'
				/>
			</Jacket>
		);

	if (type === 'close')
		return (
			<Jacket {...shared}>
				<g
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='1'
					transform='translate(0.5 0.5)'>
					<line
						fill='none'
						strokeMiterlimit='10'
						x1='19'
						y1='5'
						x2='5'
						y2='19'
					/>
					<line
						fill='none'
						strokeMiterlimit='10'
						x1='19'
						y1='19'
						x2='5'
						y2='5'
					/>
				</g>
			</Jacket>
		);

	if (type === 'chevron-right')
		return (
			<Jacket {...shared}>
				<g strokeLinecap='round' strokeLinejoin='round' fill='none'>
					<polyline points='7,2 17,12 7,22 ' transform='translate(0, 0)' />
				</g>
			</Jacket>
		);

	if (type === 'quote')
		return (
			<Jacket
				xmlns='http://www.w3.org/2000/svg'
				width='48'
				height='48'
				viewBox='0 0 48 48'>
				<path d='M21.66145,33.81676c0,4.29661-3.96109,8.22346-8.91304,8.22346C4.56585,42.04022,1,35.98671,1,27.90615 c0-9.27484,9.34862-18.21943,17.83035-21.94637l2.26574,3.64916C14.10766,12.9954,8.88433,17.58691,8.14413,25.28492h2.89106 c3.09587,0,6.31198,0.4991,8.45903,2.72402C21.02498,29.59761,21.66145,31.62025,21.66145,33.81676z M47,33.81676 c0,4.29661-3.96109,8.22346-8.91304,8.22346c-8.18256,0-11.74842-6.05352-11.74842-14.13408 c0-9.27484,9.34862-18.21943,17.83035-21.94637l2.26574,3.64916c-6.98843,3.38646-12.21176,7.97797-12.95195,15.67598 c3.15316,0,5.76908-0.11425,8.09925,0.71955C45.21084,27.30299,47,30.10812,47,33.81676z' />
			</Jacket>
		);

	if (type === 'link')
		return (
			<Jacket {...shared}>
				<g strokeLinecap='round' strokeLinejoin='round' fill='none'>
					<line x1='2' y1='22' x2='22' y2='2' />
					<polyline points='12 2 22 2 22 12' />
				</g>
			</Jacket>
		);

	console.error(
		`You've not added the correct 'type' prop to your <Icon /> component`
	);
	console.warn(
		`Remember to add "type='YOUR_ICON_NAME'" to your <Icon /> props or else your icon wont display`
	);
	return null;
}

export default React.memo(Icon);
